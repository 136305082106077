$max-mobile-width: 425px;
$tablet-width: 768px;
$desktop-width: 1200px;
$large-desktop-width: 2000px;
$custom-mid-width: 500px;

@mixin for-tablet {
  @media only screen and (min-width: $tablet-width) {
    @content;
  }
}

@mixin for-desktop {
  @media only screen and (min-width: $desktop-width) {
    @content;
  }
}

@mixin for-large-desktop {
  @media only screen and (min-width: $large-desktop-width) {
    @content;
  }
}

@mixin for-custom-mid {
  @media only screen and (min-width: $custom-mid-width) {
    @content;
  }
}

@mixin for-custom-iphone-plus {
  @media only screen and (min-device-width: 411px) and (max-device-width: 440px) {
    @content;
  }
}

@mixin fontCustom($f-size, $f-family, $f-weight, $color) {
  font-size: $f-size;
  font-family: $f-family;
  font-weight: $f-weight;
  color: $color;
}

@mixin fontBase($f-size, $f-family, $color) {
  font-size: $f-size;
  font-family: $f-family;
  color: $color;
}

@mixin fontTitle($f-size, $color) {
  font-size: $f-size;
  font-family: $font-title;
  color: $color;
  font-weight: 800;
}

@mixin fontRegular($f-size, $color) {
  font-size: $f-size;
  font-family: $font-regular;
  color: $color;
}

@mixin fontRegularCMC($f-size, $color) {
  font-size: $f-size;
  font-family: $font-poppin;
  color: $color;
}

@mixin fontTitleCMC($f-size, $color) {
  font-size: $f-size;
  font-family: $font-poppin;
  color: $color;
  font-weight: bold;
}

@mixin fontCardTitle() {
  @include fontRegular(16px, $dark-text);
  line-height: 24px;
  font-weight: 700;

  @include for-desktop {
    font-size: 22px;
    line-height: 33px;
  }
}

@mixin fontCardDescription() {
  @include fontRegular(12px, $alt-text-color);
  line-height: 18px;

  @include for-desktop {
    font-size: 16px;
    line-height: 24px;
  }
}

@mixin applyPageSectionpadding() {
  padding: $section-spacing-mobile;

  @include for-desktop {
    padding: $section-spacing-desktop;
  }
}

@mixin applyPageContainerPadding() {
  padding-top: $header-height;

  @include for-desktop {
    padding-top: $header-desktop-height;
  }
}

@mixin applyPageContainerWithGiftSectionPadding() {
  padding-top: calc(#{$header-height} + #{$gift-section-height});

  @include for-desktop {
    padding-top: calc(
      #{$header-desktop-height} + #{$gift-section-desktop-height}
    );
  }
}

@mixin applyMaxWidth() {
  margin-right: auto;
  margin-left: auto;
  max-width: $max-mobile-width;

  @include for-desktop {
    max-width: 1440px;
  }
}

@mixin row() {
  display: flex;
  flex-direction: row;
}

@mixin flex-center() {
  justify-content: center;
  align-items: center;
}

@mixin row-center() {
  @include row();
  @include flex-center();
}

@mixin column() {
  display: flex;
  flex-direction: column;
}

@mixin column-center() {
  @include column();
  @include flex-center();
}

@mixin hideScrollbar() {
  scrollbar-width: none;
}
