@import './styles/all.scss';

.c-App {
  .mobile-only {
    display: block;

    @include for-desktop {
      display: none;
    }
  }

  .desktop-only {
    display: none;

    @include for-desktop {
      display: block;
    }
  }

  .page-container {
    @include applyPageContainerPadding();

    transition: padding-top 300ms;
  }

  &--w-gift-section {
    .page-container {
      @include applyPageContainerWithGiftSectionPadding();
    }
  }
}
